


























































import { Component, Vue } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';
import GoogleLogin from 'vue-google-login';
import ShowContent from '@shared/components/ShowContent.vue';
Component.registerHooks(['validations']);

@Component({
  components: {
		GoogleLogin,
		ShowContent
  }
})
export default class RequestPasswordReset extends Vue {
  $v: any;
  requestFailed = false;
  requestSuccess = false;
  showOverlay = false;
  form = {
    username: null
  }

  validations() {
    return {
      form: {
        username: {
          required,
          email
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      username: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }
    this.requestFailed = false;
    this.requestSuccess = false;
    this.showOverlay = true;
    this.$store.dispatch('auth/requestPasswordReset', this.form.username)
      .then((res: any) =>  {
        this.requestSuccess = true;
        this.showOverlay = false;
        console.log(res);
      },
      () => {
        this.requestFailed = true;
        this.showOverlay = false;
      });
  }
}
